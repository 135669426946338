<template>
  <div class="home">
    <div class="main-bg">
      <img src="./../assets/home_main_bg.png" alt="ScoreOne" />
    </div>
    <div class="services">
      <div class="services-content">
        <h2>{{$t('Home.services.title')}}</h2>
        <h3>{{$t('Home.services.sub_title')}}</h3>
        <p>{{$t('Home.services.text')}}</p>
      </div>
    </div>
    <div class="fraud-detection">
      <div class="detection-guide">
        <div>
          <img src="./../assets/home_identity_check.png" alt="Identity Check" />
          <div class="detection-guide-content">
            <h3>{{$t('Home.detection.ic_title')}}</h3>
            <p>
              <strong>{{$t('Home.detection.ic_sub_title')}}</strong>
              <br />{{$t('Home.detection.ic_text1')}}
              <br />{{$t('Home.detection.ic_text2')}}
            </p>
          </div>
        </div>
        <div>
          <img src="./../assets/home_credit_scoring.png" alt="Credit Scoring" />
          <div class="detection-guide-content">
            <h3 class="guide-title-right">{{$t('Home.detection.cs_title')}}</h3>
            <p class="guide-article-right">
              <strong>{{$t('Home.detection.cs_sub_title')}}</strong>
              <br />{{$t('Home.detection.cs_text1')}}
              <br />{{$t('Home.detection.cs_text2')}}
            </p>
          </div>
        </div>
      </div>
      <h3>
        <span>{{$t('Home.detection.ic')}}</span>
        <span>{{$t('Home.detection.fd')}}</span>
        <span>{{$t('Home.detection.cs')}}</span>
        <div class="more-button">
          <router-link to="/techservices">{{$t('Home.detection.button')}}</router-link>
        </div>
      </h3>
    </div>
    <div class="partnerships">
      <div class="partnerships-content">
        <h2>{{$t('Home.partnerships.title')}}</h2>
        <h3>{{$t('Home.partnerships.sub_title')}}</h3>
        <p>{{$t('Home.partnerships.text')}}</p>
      </div>
    </div>
    <div class="map">
      <div class="map-content">
        <img src="./../assets/home_map.png" alt="Map" />
        <div class="map-India">
          <img
          :src="[ selected.in ? require('./../assets/home_map_India_blue.png') : require('./../assets/home_map_India_lightblue.png') ]"
          alt="India"
          @click="selected = { ph: false, vi: false, in: true }"
          />
          <span :class="{ selectedTitle: selected.in }">{{$t('Home.map.in')}}</span>
        </div>
        <div class="map-Vietnam">
          <img
            :src="[ selected.vi ? require('./../assets/home_map_Vietnam_blue.png') : require('./../assets/home_map_Vietnam_lightblue.png') ]"
            alt="Vietnam"
            @click="selected = { ph: false, vi: true, in: false }"
          />
          <span :class="{ selectedTitle: selected.vi }">{{$t('Home.map.vi')}}</span>
        </div>
        <div class="map-Philippines">
          <img
          :src="[ selected.ph ? require('./../assets/home_map_Philippines_blue.png') : require('./../assets/home_map_Philippines_lightblue.png') ]"
          alt="Philippines"
          @click="selected = { ph: true, vi: false, in: false }"
          />
          <span :class="{ selectedTitle: selected.ph }">{{$t('Home.map.ph')}}</span>
        </div>
        <ul class="map-button">
          <li @click="selected = { ph: true, vi: false, in: false }">
            <span :class="{ selected: selected.ph }">- {{$t('Home.map.ph')}}</span>
          </li>
          <li @click="selected = { ph: false, vi: true, in: false }">
            <span :class="{ selected: selected.vi }">- {{$t('Home.map.vi')}}</span>
          </li>
          <li @click="selected = { ph: false, vi: false, in: true }">
            <span :class="{ selected: selected.in }">- {{$t('Home.map.in')}}</span>
          </li>
        </ul>
        <div class="country-case">
          <table  v-for="(items,index) in countryIntroduce" :key="index" v-show="selected[index]">
            <thead>
              <tr>
                <th colspan="2">{{ $t(`Home.map.countryIntroduce.${ items.title }`) }}</th>
              </tr>
            </thead>
            <tr v-for="(item,i) in items" :key="i" v-show="i !== 'title'">
              <td>{{ $t('Home.map.countryIntroduce.'+i) }}</td>
              <td>
                <strong>{{item}}</strong>
              </td>
            </tr>
          </table>
          <div class="line-white"></div>
        </div>
        <transition-group mode="out-in" enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown" :duration="{enter: 500, leave: 500}">
          <div class="line-in" v-show="selected.in" key="in"></div>
          <div class="line-vi" v-show="selected.vi" key="vi"></div>
          <div class="line-ph" v-show="selected.ph" key="ph"></div>
        </transition-group>
      </div>
    </div>
    <div class="case">
      <div class="case-content">
          <h2>{{$t('Home.case.title')}}</h2>
          <p>{{$t('Home.case.text')}}</p>
          <div>
            <router-link to="/sustainability">{{$t('Home.case.button')}}</router-link>
          </div>
      </div>
    </div>
    <div class="about-scoreone">
      <div class="about-content">
        <h2>{{$t('Home.about.title')}}</h2>
        <div class="pictures-wall">
          <div>
            <transition mode="out-in" name="opacity">
              <div class="first-picture" v-if="picWallShow.first" @mouseenter="picWallShow.first = false" key="default">
                <h3>{{$t('Home.about.sub_title1')}}</h3>
                <img src="./../assets/home_our_visions_and_missions_and_goals.png" alt="Our Visions & Missions & Goals"/>
              </div>
              <div class="first-picture-hover" v-else @mouseleave="picWallShow.first = true" key="hover">
                <h3>{{$t('Home.about.visions')}}</h3>
                <p>{{$t('Home.about.v_text')}}</p>
                <h3>{{$t('Home.about.missions')}}</h3>
                <p>{{$t('Home.about.m_text')}}</p>
                <h3>{{$t('Home.about.goals')}}</h3>
                <p>{{$t('Home.about.g_text')}}</p>
                <div class="more-button">
                  <router-link to="/about">{{$t('Home.about.button')}}</router-link>
                </div>
              </div>
            </transition>
          </div>
          <div>
            <transition mode="out-in" name="opacity">
              <div class="third-picture" v-if="picWallShow.third" @mouseenter="picWallShow.third = false" key="default">
                <h3>{{$t('Home.about.sub_title3')}}</h3>
                <img
                  src="./../assets/home_sustainability_and_commitments.png"
                  alt="SUSTAINABILITY AND COMMITMENTS"
                />
              </div>
              <div class="third-picture-hover" v-else @mouseleave="picWallShow.third = true" key="hover">
                <h3>{{$t('Home.about.sub_title3')}}</h3>
                <p>
                  •&nbsp;{{$t('Home.about.sac_text1')}}
                  <br />•&nbsp;{{$t('Home.about.sac_text2')}}
                  <br />•&nbsp;{{$t('Home.about.sac_text3')}}
                  <br />•&nbsp;{{$t('Home.about.sac_text4')}}
                  <br />•&nbsp;{{$t('Home.about.sac_text5')}}
                </p>
                <div class="more-button">
                  <router-link to="/sustainability">{{$t('Home.about.button')}}</router-link>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: function() {
    return {
      selected: { ph: true, vi: false, in: false },
      countryIntroduce: {
        ph: {
          title: 'Country profile of the Philippines',
          Population: '101 Millions',
          'GDP / capital': '$3104 (IMF)',
          'GDP growth (2018)': '6.2%',
          'Internet penetration': '60%',
          'Rank for number of users': '11th (63millions+)',
          'Bank account penetration': '30%',
          'Credit card penetration': '3.2%',
          'Borrowing habits': 'very high (+70% borrowed money in the past year, less than 10% borrowed money formally)'
        },
        vi: {
          title: 'Country profile of Vietnam',
          Population: '96 Millions',
          'GDP / capital': '$2551 (IMF)',
          'GDP growth (2018)': '7.1%',
          'Internet penetration': '50%',
          'Rank for number of users': '18th (47millions+)',
          'Bank account penetration': '31%',
          'Credit card penetration': '1.9%',
          'Borrowing habits': 'high (+50% borrowed money in the past year, 19% formally)'
        },
        in: {
          title: 'Country profile of India',
          Population: '1.297 Billions',
          'GDP / capital': '$2036 (IMF)',
          'GDP growth (2018)': '7%',
          'Internet penetration': '34%',
          'Rank for number of users': '2nd (460millions+)',
          'Bank account penetration': '80% (more than half are inactive – World Bank)',
          'Credit card penetration': '40% (less than 15% used a credit card in the last year – World Bank)',
          'Borrowing habits': 'high (+50% borrowed money in the past year, only 10% formally)'
        }
      },
      picWallShow: { first: true, second: true, third: true }
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.selectedTitle {
  color: #3684f7 !important;
  font-weight: bold !important;
}
.home {
  .main-bg {
    > img {
      display: block;
      width: 100%;
      min-width: 1200px;
      height: auto;
    }
  }
  .services {
    width: 100%;
    min-width: 1200px;
    background: url("./../assets/home_scoreone_servicers.png");
    background-size: 100% 100%;
    .services-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      h2 {
        padding: 52px 0 90px;
        font: normal bold 50px Arial;
      }
      h3 {
        margin: 0 0 25px;
        font: normal bold 38px "Arial";
      }
      p {
        padding-bottom: 48px;
        font: normal 500 24px/36px "Arial";
        text-align: left;
        letter-spacing: 2px;
      }
    }
  }
  .fraud-detection {
    width: 100%;
    min-width: 1200px;
    .detection-guide {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      > div {
        position: relative;
        width: 520px;
        img {
          width: 100%;
          height: auto;
        }
        .detection-guide-content {
          width: 100%;
          box-sizing: border-box;
          padding: 20px;
          position: absolute;
          top: 0;
          left: 0;
          text-align: left;
          h3 {
            width: 302px;
            font: normal bold 23px/36px Arial;
            letter-spacing: 1px;
            color: #D8FFF9;
          }
          p {
            width: 310px;
            font: normal 500 14px/26px Arial;
            color: #fff;
            letter-spacing: 1px;
            text-align: justify;
            strong {
              color: #D8FFF9;
              font-size: 16px;
              font-style: italic;
            }
          }
          .guide-title-right {
            width: 392px;
          }
          .guide-article-right {
            width: 360px;
          }
        }
      }
    }
    > h3 {
      width: 1200px;
      margin: 40px auto 0;
      padding-bottom: 25px;
      font: normal bold 24px Arial;
      color: #4a719b;
      text-align: center;
      span:nth-child(2) {
        margin: 0 180px;
      }
      .more-button {
        margin-top: 25px;
        a {
          padding: 10px 40px;
          border-radius: 4px;
          background: #5390ea;
          color: #fff;
          font: normal bold 14px "Microsoft YaHei";
        }
      }
    }
  }
  .partnerships {
    width: 100%;
    min-width: 1200px;
    background: url("./../assets/home_scoreone_partnerships.png");
    background-size: 100% 100%;
    .partnerships-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      h2 {
        padding: 52px 70px 90px;
        font: normal bold 50px Arial;
      }
      h3 {
        margin: 0 0 25px;
        font: normal bold 38px "Arial";
      }
      p {
        padding-bottom: 48px;
        font: normal 500 24px/36px "Arial";
        text-align: left;
        letter-spacing: 2px;
        text-align: justify;
      }
    }
  }
  .map {
    width: 100%;
    min-width: 1200px;
    .map-content {
      width: 1200px;
      height: 684px;
      margin: 0 auto;
      position: relative;
      > img {
        position: absolute;
        right: 0;
        width: 1100px;
        height: 684px;
      }
      .map-India {
        position: absolute;
        top: 112px;
        left: 162px;
        width: 457px;
        img {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        span {
          position: absolute;
          right: 198px;
          bottom: 100px;
          color: #323232;
          font: 16px Arial;
        }
      }
      .map-Vietnam {
        position: absolute;
        bottom: 172px;
        right: 354px;
        width: 135px;
        z-index: 999;
        img {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        span {
          position: absolute;
          top: 92px;
          right: -21px;
          color: #323232;
          font: 16px Arial;
        }
      }
      .map-Philippines {
        position: absolute;
        bottom: 123px;
        right: 73px;
        width: 163px;
        img {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        span {
          position: absolute;
          top: 75px;
          left: -52px;
          color: #323232;
          font: 16px Arial;
        }
      }
      .map-button {
        position: absolute;
        left: -11px;
        top: 125px;
        li {
          line-height: 60px;
          span {
            padding: 10px 15px;
            font: normal bold 28px Arial;
            color: #5390ea;
            border-radius: 4px;
            cursor: pointer;
          }
          .selected {
            color: #fff;
            background: #5390ea;
          }
        }
      }
      .country-case {
        position: absolute;
        top: 25px;
        right: 8px;
        width: 400px;
        table {
          width: 100%;
          background: #394555;
          padding: 2px 10px;
          font: normal 500 12px/20px Arial;
          color: #fff;
          border-radius: 10px;
          th {
            font: normal bold 15px/40px Arial;
            padding-bottom: 10px;
          }
          > tr {
              &:nth-child(odd) {
              background: #5B6879;
            }
            td {
              padding: 0 10px;
              &:nth-child(1) {
                width: 40%;
                text-align: right;
              }
            }
          }
        }
        .line-white {
          position: absolute;
          z-index: 999;
          width: 80%;
          top: 37px;
          height: 2px;
          background: #fff;
        }
      }
      .line-in, .line-vi, .line-ph {
        position: absolute;
        top: 62px;
        right: 408px;
        border-top: 2px solid #3684F7;
        border-left: 2px solid #3684F7;
      }
      .line-in {
        width: 420px;
        height: 140px;
      }
      .line-vi {
        width: 41px;
        height: 218px;
      }
      .line-ph {
        right: 200px;
        width: 420px;
        height:400px;
        border-bottom: 2px solid #3684F7;
      }
    }
  }
  .case {
    width: 100%;
    min-width: 1200px;
    box-sizing: border-box;
    padding: 80px 0;
    .case-content {
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 43px;
      text-align: center;
      h2 {
        color: #323232;
        font: normal bold 40px Arial;
        margin-bottom: 30px;
      }
      p {
        color: #323232;
        font: normal bold 18px/32px Arial;
        text-align: justify;
      }
      div {
        height: 40px;
        line-height: 40px;
        margin-top: 18px;
        a {
          background: #5390ea;
          color: #fff;
          font: normal bold 18px Arial;
          padding: 10px 18px;
          border-radius: 4px;
        }
      }
    }
  }
  .about-scoreone {
    width: 100%;
    margin-bottom: 200px;
    .about-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      h2 {
        padding: 80px 110px;
        font: normal bold 48px Arial;
        color: #1c2e3e;
      }
      .pictures-wall {
        width: 100%;
        display: flex;
        justify-content: center;
        > div {
          width: 33.3%;
          height: 254px;
          .first-picture,
          .second-picture,
          .third-picture {
            width: 360px;
            position: relative;
            margin: 0 auto;
            h3 {
              position: absolute;
              top: 20px;
              left: 20px;
              font: normal bold 18px Arial;
              color: #fff;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .third-picture {
            h3 {
              color: #1c2e3e;
            }
          }
          .first-picture-hover,
          .second-picture-hover,
          .third-picture-hover {
            width: 360px;
            margin: 0 auto;
            height: 100%;
            background: #e9f1fc;
            color: #1c2e3e;
            text-align: left;
            box-sizing: border-box;
            padding: 14px 20px 0 20px;
            border: 1px solid #dedede;
            border-radius: 4px;
            > h3 {
            padding-bottom: 4px;
            font: normal bold 15px Arial;
            }
            .more-button {
            width: 100%;
            text-align: center;
            margin-top: 16px;
            a {
              color: #fff;
              font: normal bold 16px Arial;
              background: #5390ea;
              padding: 6px 18px;
              border-radius: 4px;
            }
            }
          }
          .first-picture-hover {
            p {
              margin-bottom: 5px;
              font: normal 500 12px/15px Arial;
              text-align: justify;
            }
          }
          .second-picture-hover {
            .avatar {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            padding: 20px 0 35px;
            margin-bottom: 48px;
            > div {
              width: 85px;
              height: 85px;
              position: relative;
              border-radius: 50%;
              h4 {
                position: absolute;
                left: 26px;
                bottom: -30px;
              }
            }
            .CEO-avatar {
              background: url("./../assets/sus_users_Melvie.png") center center no-repeat;
              background-size: cover;
            }
            .CTO-avatar {
              background: url("./../assets/sus_users_Melvie.png") center center no-repeat;
              background-size: cover;
            }
            .COO-avatar {
              background: url("./../assets/sus_users_Melvie.png") center center no-repeat;
              background-size: cover;
            }
            }
          }
          .third-picture-hover {
            p {
              margin: 20px 0 28px;
              font: normal 500 14px/28px Arial;
            }
          }
        }
      }
    }
  }
}

</style>
